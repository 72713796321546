@media (min-width: 992px) {
  .brand {
    background-color: #041E42;
    box-shadow: none;
  }
  .brand .btn i {
    color: rgba(255, 255, 255, 0.65);
  }
  .brand .btn .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: rgba(255, 255, 255, 0.65);
  }
  .brand .btn .svg-icon svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
  .brand .btn.active .svg-icon svg g [fill], .brand .btn:hover .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #FFF;
  }
  .brand .btn.active .svg-icon svg:hover g [fill], .brand .btn:hover .svg-icon svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
  .brand .btn.active i, .brand .btn:hover i {
    color: #FFF;
  }
}
@media (max-width: 991.98px) {
  .header-mobile {
    background-color: #041E42;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
  }
  .header-mobile .burger-icon span {
    background-color: #FFF;
  }
  .header-mobile .burger-icon span::before, .header-mobile .burger-icon span::after {
    background-color: #FFF;
  }
  .header-mobile .burger-icon:hover span {
    background-color: #FFF;
  }
  .header-mobile .burger-icon:hover span::before, .header-mobile .burger-icon:hover span::after {
    background-color: #FFF;
  }
  .header-mobile .burger-icon-active span {
    background-color: #FFF;
  }
  .header-mobile .burger-icon-active span::before, .header-mobile .burger-icon-active span::after {
    background-color: #FFF;
  }
  .header-mobile .btn i {
    color: #FFF;
  }
  .header-mobile .btn .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #FFF;
  }
  .header-mobile .btn .svg-icon svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
  .header-mobile .btn.active .svg-icon svg g [fill], .header-mobile .btn:hover .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #FFF;
  }
  .header-mobile .btn.active .svg-icon svg:hover g [fill], .header-mobile .btn:hover .svg-icon svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
  .header-mobile .btn.active i, .header-mobile .btn:hover i {
    color: #FFF;
  }
}